<template>
  <b-card-code title="Detail Type Jam Belajar">
    <b-row class="match-height">
      <b-col
        cols="3"
        class="border-left"
      >
        <h5 class="mb-0">
          Nama
        </h5>
        <div class="mb-2 text-secondary">
          {{ name }}
        </div>
      </b-col>
    </b-row>

    <template #code>
      {{ codeIcon }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BCol,
} from 'bootstrap-vue'
// import { codeIcon } from './code'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'DataSiswaEdit',
  components: {
    BCardCode,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    // userData: {
    //   type: Object,
    //   required: true,
    // },
  },
  data: () => ({
    studentDatas: {},
  }),
  computed: {
    name() {
      return this.studentDatas?.name
    },
  },
  created() {
    this.detailStudent()
  },
  methods: {
    async detailStudent() {
      try {
        const employeeid = this.$route.params.id
        const response = await this.$http.get(`/studyhourtypes/${employeeid}`)
        this.studentDatas = response.data.data
      } catch (err) {
        console.log(err)
      }
    },
  },
  setup() {
    // const { resolveUserRoleVariant } = useUsersList()
    return {
      avatarText,
      // resolveUserRoleVariant,
    }
  },
}
</script>
